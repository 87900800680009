<template>
    <comp-form title="一级分类" ref="comp_form" submit-api="/gateway/apps/o2o/api/terminal/category/addTerminalCategory" edit-api="/gateway/apps/o2o/api/terminal/category/modifyerminalCategory" id-key="labelById" height="auto" @on-submit="$emit('on-submit')" :submit-before="onSubmitBefore">
        <template v-slot:form-box="data">
            <elem-form-item title="分类名称" name="name">
                <template v-slot:default="item">
                    <Input style="width:300px" v-model.trim="data.form.name" :placeholder="item.cfg.ph"></Input>
                </template>
            </elem-form-item>
            <elem-form-item title="排序" name="sort">
                <template v-slot:default="item">
                    <Input style="width:300px" type="number" v-model.trim="data.form.sort" :placeholder="item.cfg.ph"></Input>
                </template>
            </elem-form-item>
            <elem-form-item title="标签图标" name="icon">
                <elem-upload :value="data.form.icon" @on-change="onChangeIcon"></elem-upload>
            </elem-form-item>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "../../components/CompForm.vue"
import ElemUpload from "../../components/elem-upload.vue"
import ElemFormItem from "../../components/elem-form-item.vue"

export default {
    components: { CompForm, ElemUpload, ElemFormItem },

    methods: {
        /**
         * 打开表单窗口
         */
        open(id, data) {
            this.$refs.comp_form.open(id, data)
        },

        onChangeIcon(e) {
            this.$refs.comp_form.setValue("icon", e.value.url)
        },

        onSubmitBefore(data) {
            data.terminalGroupId = this.$core.getUrlParam("t")
        },
    },
}
</script>
